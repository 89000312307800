import { useEffect, useState } from 'react';
import { useKexNavigate } from '../../Kex/KexRouter/KexRouter';
import { GetOrder } from '../../OrderComponent/Order';
import { Add, Delete, Update } from '../../OrderComponent/RecurringOrder';
import { getUrlParameter } from '../../Shared/Common/Helpers';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import PageModelBase from '../../Shared/Models/PageModelBase.interface';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { useUserStateData } from '../../Shared/UserContextProvider/UserContextProvider';
import { GetCart } from '../CartPage/Cart';
import RecurringOrderInformation from '../../OrderComponent/RecurringOrderInformation';
import { styled } from '../../stitches.config';
import { H4 } from '../../Atoms/Typography/Headings/Heading';
import CartProductList from '../../Organisms/ProductList/CartProductList';

const initialState = {
  name: '',
  intervalInDays: '0',
  startDate: '',
};

function RecurringOrderCartPage() {
  const { cart, isLoading } = GetCart();
  const { channelId } = useCurrentPage<PageModelBase>();
  var query = getUrlParameter('recurringOrderId');

  const kexNavigate = useKexNavigate();
  const { organizationId } = useUserStateData();

  const [recurringOrderData, setRecurringOrderData] =
    useState<any>(initialState);

  const [isExistingOrder, setIsExistingOrder] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string>('');

  const {
    translations: {
      'cartPage/cartHeader': cartHeader,
      'common/loading': loadingLabel,
    },
    staticPages: { recurringOrdersPage },
    requestToken,
    languageRoute,
  } = useAppSettingsData();

  const handleUpdateData = (value: string, name: string) => {
    const data = { ...recurringOrderData } as any;
    data[name] = value;

    setRecurringOrderData(data);
  };

  const handleUpdateQuantityFromList = (
    productCode: string,
    quantity: number
  ) => {
    debugger;
    const data = { ...recurringOrderData } as any;
    const item = data.items.find((item: any) => item.code === productCode);

    if (item) {
      item.quantity = quantity;
    }

    setRecurringOrderData(data);
  };

  const handleRemoveItemFromList = (productCode: string) => {
    const data = { ...recurringOrderData } as any;
    setRecurringOrderData(data);
  };

  const getOrder = async () => {
    const url = `/api/${
      languageRoute || 'en'
    }/recurringorder/GetOrder?organizationId=${organizationId}&recurringOrderId=${query}`;

    const data = await GetOrder(channelId, url, setLoading);

    setLoading(false);

    if (data?.order) {
      setRecurringOrderData(data.order);
      setIsExistingOrder(true);
    }

    setLoading(false);
  };

  const addOrder = async () => {
    const data = await Add(
      channelId,
      requestToken,
      languageRoute,
      organizationId,
      recurringOrderData.name,
      cart.lineItems,
      recurringOrderData.startDate ?? new Date().toISOString(),
      parseInt(recurringOrderData.intervalInDays),
      setLoading,
      setError,
      setValidationMessage
    );
    if (data.success) {
      kexNavigate(recurringOrdersPage);
    }
  };

  const updateOrder = async (onHold?: boolean) => {
    const data = await Update(
      channelId,
      requestToken,
      languageRoute,
      organizationId,
      recurringOrderData.id,
      recurringOrderData.name,
      cart.lineItems,
      recurringOrderData.startDate ?? new Date().toISOString(),
      parseInt(recurringOrderData.intervalInDays),
      onHold ?? recurringOrderData.onHold,
      setLoading,
      setError,
      setValidationMessage
    );
    if (data?.order) {
      setRecurringOrderData(data.order);
    }
  };

  const handleSetOnHold = async () => updateOrder(!recurringOrderData.onHold);

  const handleDeleteOrder = async () => {
    const data = await Delete(channelId, languageRoute, organizationId, query);
    if (data) {
      kexNavigate(recurringOrdersPage);
    }
  };

  useEffect(() => {
    if (query) {
      getOrder();
    }
  }, [channelId, query]);

  return (
    <Main>
      {isLoading && <div>{loadingLabel}</div>}
      {!isLoading && cart && (
        <CartContainer>
          <CartContent>
            <CartHeader>{cartHeader}</CartHeader>
            <CartProductList products={cart.lineItems} isMiniCart={false} />
          </CartContent>
          <RecurringOrderInformation
            handleUpdateData={handleUpdateData}
            recurringOrderData={recurringOrderData}
            addOrder={addOrder}
            handleSetOnHold={handleSetOnHold}
            handleDeleteOrder={handleDeleteOrder}
            updateOrder={updateOrder}
            isExistingOrder={isExistingOrder}
            validationMessage={validationMessage}
            error={error}
            loading={loading}
          />
        </CartContainer>
      )}
    </Main>
  );
}

const CartContainer = styled('div', {
  display: 'grid',
  g: 4,
  gridTemplateColumns: '1fr',
  wh: '100%',
  justifyContent: 'center',
  my: 4,
  mx: 'auto',
  px: 4,
  '@mediaMinLarge': {
    gridTemplateColumns: '6.5fr 3.5fr',
    g: 8,
    my: 13,
    mx: 'auto',
  },
  maxW: '$contentMaxWidth',
});

const CartContent = styled('div', {
  fontWeight: '$fw300',
  color: '$grey900',
  backgroundColor: '$white',
  p: 4,
  mt: 0,
  '@mediaMinLarge': {
    p: 8,
  },
});

const CartHeader = styled(H4, {
  mb: 4,
  fs: 14,
  fontWeight: '$fw300',
});

const Main = styled('div', {
  backgroundColor: '$grey200',
});

export default RecurringOrderCartPage;
