import { KexInputValidation } from '../Shared/Input/KexInputValidation';
import KexInput from '../Shared/Input/KexInput';
import { useTranslationData } from '../Shared/Providers/TranslationProvider';
import { ExclamationMarkIcon } from '../Atoms/Icons';
import { styled } from '../stitches.config';
import CtaButton from '../Atoms/Buttons/CtaButton';

type PropType = {
  recurringOrderData: any;
  handleUpdateData: (value: string, name: string) => void;
  addOrder: () => void;
  updateOrder: () => void;
  handleSetOnHold: () => void;
  handleDeleteOrder: () => void;
  isExistingOrder: boolean;
  validationMessage: string;
  error: boolean;
  loading: boolean;
};

function RecurringOrderInformation({
  recurringOrderData,
  handleDeleteOrder,
  handleSetOnHold,
  handleUpdateData,
  addOrder,
  updateOrder,
  error,
  isExistingOrder,
  loading,
  validationMessage,
}: PropType) {
  const {
    'recurringOrderCartPage/name': recurringOrderLabel,
    'recurringOrderCartPage/subscribe': subscribeLabel,
    'recurringOrderCartPage/updateOrder': updateOrderLabel,
    'recurringOrderCartPage/recurringOrderValidation':
      recurringOrderValidationLabel,
    'recurringOrderCartPage/date': recurringOrderDateLabel,
    'recurringOrderCartPage/interval': recurringOrderIntervalLabel,
    'recurringOrderCartPage/onHold': onHoldLabel,
    'recurringOrderCartPage/activate': activateLabel,
    'recurringOrderCartPage/delete': deleteLabel,
  } = useTranslationData();

  return (
    <Container>
      {error && (
        <ValidationMessage>
          <ExclamationMarkIcon />{' '}
          <ErrorMessage>{validationMessage}</ErrorMessage>
        </ValidationMessage>
      )}
      <KexInputValidation>
        <KexInput
          type="text"
          name="recurringOrderName"
          title={recurringOrderLabel}
          value={recurringOrderData.name}
          onChange={(value: string) => handleUpdateData(value, 'name')}
          onKeyDown={(e: any) =>
            e.key === 'Enter' && (isExistingOrder ? updateOrder() : addOrder())
          }
          validation={{
            required: true,
            errorMessage: recurringOrderValidationLabel,
          }}
        />

        <KexInput
          type="date"
          name="recurringOrderDate"
          title={recurringOrderDateLabel}
          onChange={(value: string) => handleUpdateData(value, 'startDate')}
          onKeyDown={(e: any) =>
            e.key === 'Enter' && (isExistingOrder ? updateOrder() : addOrder())
          }
          validation={{
            required: true,
            errorMessage: recurringOrderValidationLabel,
          }}
        />

        <KexInput
          type="number"
          name="recurringOrderInterval"
          title={recurringOrderIntervalLabel}
          value={recurringOrderData.intervalInDays}
          onChange={(value: string) =>
            handleUpdateData(value, 'intervalInDays')
          }
          onKeyDown={(e: any) =>
            e.key === 'Enter' && (isExistingOrder ? updateOrder() : addOrder())
          }
          validation={{
            required: true,
            errorMessage: recurringOrderValidationLabel,
          }}
        />
      </KexInputValidation>

      <div>
        <StyledButton
          size={'s'}
          disabled={loading}
          onClick={isExistingOrder ? updateOrder : addOrder}
        >
          {isExistingOrder ? updateOrderLabel : subscribeLabel}
        </StyledButton>
        {isExistingOrder && (
          <>
            <StyledButton
              size="s"
              disabled={loading}
              onClick={handleDeleteOrder}
            >
              {deleteLabel}
            </StyledButton>
          </>
        )}
        {isExistingOrder && (
          <>
            <StyledButton size="s" disabled={loading} onClick={handleSetOnHold}>
              {recurringOrderData.onHold ? activateLabel : onHoldLabel}
            </StyledButton>
          </>
        )}
      </div>
    </Container>
  );
}

const Container = styled('div', {
  backgroundColor: '$white',
  p: 4,
  '@mediaMinLarge': {
    p: 8,
  },
});

const StyledButton = styled(CtaButton, {
  w: '100%',
  '&:first-of-type': {
    mt: 2,
  },
  '&:not(:last-of-type)': {
    mb: 4,
  },
});

const ErrorMessage = styled('p', {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '0%',
});

const ValidationMessage = styled('div', {
  backgroundColor: '$errorPrimary',
  color: '$errorText',
  p: 6,
  my: 6,
  display: 'flex',
});

export default RecurringOrderInformation;
